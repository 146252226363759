import { Col, Image, Row } from "antd";

export const Gallery = ({ images }) => {
  const listImages = images.map((data) => {
    return (
      <Col xs={12} sm={12} md={4}>
        <Image src={data.url} />
      </Col>
    );
  });

  return (
    <Row gutter={[18, 18]}>
      <Image.PreviewGroup>{listImages}</Image.PreviewGroup>
    </Row>
  );
};
