import { Avatar, Button, Table, Tooltip } from "antd";

import { bathroom, bedroom } from "./iconData";
import { useEffect, useState } from "react";
import axios from "axios";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { EnquireModalRental } from "./enquireModalRental";

export const ListCondominiumRentalData = ({
  developmentId,
  developmentName,
}) => {
  const [rentalData, setRentalData] = useState([]);

  useEffect(() => {
    axios
      .post(".netlify/functions/getUnitListingRentData", {
        developmentId,
        published: false,
      })
      .then(function (response) {
        setRentalData(response.data.unitsDocs.data);
      });
  }, [developmentId, setRentalData]);

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "id",
      render: (item, record) => (
        <Tooltip
          placement="top"
          title={
            record.contactInfo[0].intermediaryOrganisation.data.companyName
          }
          arrow={true}
        >
          <Avatar
            src={
              record.contactInfo[0].intermediaryOrganisation.data.companyLogo
            }
          />
        </Tooltip>
      ),
      responsive: ["md"],
    },
    {
      title: "Rent",
      dataIndex: "price",
      key: "price",
      render: (item) =>
        Intl.NumberFormat("en-US").format(Math.floor(Number(item))),
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: bedroom,
      dataIndex: "bedrooms",
      key: "bedrooms",
      render: (text) => <span>{text}</span>,
    },
    {
      title: bathroom,
      dataIndex: "bathrooms",
      key: "bathrooms",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
      render: (item) => (item ? item : "-"),
      responsive: ["md"],
    },
    {
      title: "Sqft",
      dataIndex: "squareFeet",
      key: "sqft",
      responsive: ["md"],
      render: (item) =>
        Intl.NumberFormat("en-US").format(Math.floor(Number(item))),
      sorter: (a, b) => Number(a.squareFeet) - Number(b.squareFeet),
    },
    {
      title: <small>Rent/SQFT (LKR)</small>,
      dataIndex: "squareFeet",
      key: "per sqft",
      responsive: ["md"],
      render: (item, record) =>
        Intl.NumberFormat("en-US").format(
          Math.floor(Number(record.price) / Number(record.squareFeet))
        ),
      sorter: (a, b) =>
        Math.floor(Number(a.price) / Number(a.squareFeet)) -
        Math.floor(Number(b.price) / Number(b.squareFeet)),
    },
    {
      title: "Parking",
      dataIndex: "parkingSpace",
      key: "id",
    },
    {
      title: "Contact",
      dataIndex: "",
      key: "id",
      render: (item, record) => (
        <>
          <EnquireModalRental
            rentalData={record}
            developmentName={developmentName}
            developmentId={developmentId}
          />
        </>
      ),
    },
  ];
  return (
    <Table
      title={() => <Button>Add Property</Button>}
      columns={columns}
      size="small"
      dataSource={rentalData}
      expandable={{
        expandedRowRender: (record) => (
          <>
            {record.advancePayment !== 0 && (
              <Button shape="round" style={{ margin: "3px" }}>
                <small>Advance Payment: {record.advancePayment} Months</small>
              </Button>
            )}
            {record.depositTerm !== 0 && (
              <Button style={{ margin: "3px" }}>
                <small>Deposit Term: {record.depositTerm} Months</small>
              </Button>
            )}
            {record.minimumTerm !== 0 && (
              <Button shape="round" style={{ margin: "3px" }}>
                <small>Minimum Term: {record.minimumTerm} Months</small>
              </Button>
            )}
            <Button shape="round" style={{ margin: "3px" }}>
              <small>{record.furnishingStatus.data.title}</small>
            </Button>
            <Button shape="round" style={{ margin: "3px" }}>
              <small>
                Short Lets{" "}
                {record.shortTermRental ? (
                  <CheckCircleOutlined
                    style={{ color: "green", fontSize: "20px" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ color: "red", fontSize: "20px" }}
                  />
                )}
              </small>
            </Button>
            <Button shape="round" style={{ margin: "3px" }}>
              <small>
                {"Bills Included "}
                {record.billsIncluded ? (
                  <CheckCircleOutlined
                    style={{ color: "green", fontSize: "20px" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ color: "red", fontSize: "20px" }}
                  />
                )}
              </small>
            </Button>
            <br></br>
            <br></br>
          </>
        ),
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}
    />
  );
};
