import { Avatar, Button, Table, Tooltip } from "antd";

import { useEffect, useState } from "react";
import { bathroom, bedroom } from "./iconData";
import axios from "axios";
import { Gallery } from "./gallery";
import { EnquireModal } from "./enquireModal";

export const ListCondominiumSaleData = ({ developmentId, developmentName }) => {
  const [saleData, setSaleData] = useState([]);
  console.log(developmentName);

  useEffect(() => {
    axios
      .post(".netlify/functions/getUnitListingData", {
        developmentId,
        published: false,
      })
      .then(function (response) {
        setSaleData(response.data.unitsDocs.data);
      });
  }, [developmentId, setSaleData]);

  const columns = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (item, record) => (
        <Tooltip
          placement="top"
          title={
            record.contactInfo[0].intermediaryOrganisation.data.companyName
          }
          arrow={true}
        >
          <Avatar
            src={
              record.contactInfo[0].intermediaryOrganisation.data.companyLogo
            }
          />
        </Tooltip>
      ),
      responsive: ["md"],
    },
    {
      title: "Price (LKR)",
      dataIndex: "price",
      key: "price",
      render: (item) => Intl.NumberFormat("en-US").format(item),
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: bedroom,
      dataIndex: "bedrooms",
      key: "bedrooms",
      render: (text) => <span>{text}</span>,
    },
    {
      title: bathroom,
      dataIndex: "bathrooms",
      key: "bathrooms",
    },
    {
      title: "Sqft",
      dataIndex: "squareFeet",
      key: "sqft",
      responsive: ["md"],
      render: (item) => Intl.NumberFormat("en-US").format(item),
      sorter: (a, b) => Number(a.squareFeet) - Number(b.squareFeet),
    },
    {
      title: <small>per SQFT (LKR)</small>,
      dataIndex: "squareFeet",
      key: "per sqft",
      responsive: ["md"],
      render: (item, record) =>
        Intl.NumberFormat("en-US").format(
          Math.floor(Number(record.price) / Number(record.squareFeet))
        ),
      sorter: (a, b) =>
        Math.floor(Number(a.price) / Number(a.squareFeet)) -
        Math.floor(Number(b.price) / Number(b.squareFeet)),
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
      responsive: ["md"],
    },
    {
      title: "Yield %",
      dataIndex: "yield",
      key: "yield",
      responsive: ["md"],
    },

    {
      title: "Enquire",
      dataIndex: "",
      key: "id",
      render: (item, record) => (
        <>
          <EnquireModal
            saleData={record}
            developmentName={developmentName}
            developmentId={developmentId}
          />
        </>
      ),
    },
  ];

  return (
    <Table
      size="small"
      title={() => (
        <Button size="small">
          <small>Add Property</small>
        </Button>
      )}
      columns={columns}
      dataSource={saleData}
      expandable={{
        expandedRowRender: (record) => (
          <p
            style={{
              margin: 0,
            }}
          >
            {
              "Service Charge: LKR15,000, estimated Water Bill: 4500, Sinking Fund: LKR5000"
            }
            <br></br>
            <br></br>
            <Gallery images={record.images} />
            <br></br>
            {!record.matterport && <Button>Virtual Tour</Button>}
          </p>
        ),
        rowExpandable: (record) => record.images.length !== 0,
      }}
    />
  );
};
