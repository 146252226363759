import { Button, Col, Row } from "antd";

export const CondominiumDetails = ({ description, descriptionStatsData }) => {
  const { developer, completionYear, totalUnits, tenure, floors } =
    descriptionStatsData;

  const descriptionStats = [
    {
      title: "Developer",
      value: developer,
    },
    {
      title: "Completed",
      value: completionYear,
    },
    {
      title: "Units",
      value: totalUnits,
    },
    {
      title: "Floors",
      value: floors,
    },
    {
      title: "Tenure",
      value: tenure,
    },
  ];

  const listDescriptionStats = descriptionStats.map((data) => {
    return (
      <Button size="small" style={{ marginRight: "3px" }} shape="round">
        <small>{data.title} </small> : {data.value}
      </Button>
    );
  });

  return (
    <Row gutter={[18, 18]}>
      <Col span={24}>
        <div>{listDescriptionStats}</div>
        <br></br>
        <div>{description}</div>
      </Col>
    </Row>
  );
};
