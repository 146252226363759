import { Avatar, Button, List } from "antd";

export const Agents = () => {
  const agentData = [
    {
      avatar:
        "https://media.licdn.com/dms/image/C4D03AQGjtjaFmKjAmw/profile-displayphoto-shrink_200_200/0/1545890996096?e=1708560000&v=beta&t=uiZjm1sNAv0PGEiBCxO184J6S5wo661QiUpbZuW6fP4",
      name: "Chandaka de Soysa",
      company: "ACQUEST (Pvt) Ltd",
      number: "",
      email: "",
    },
    {
      avatar:
        "https://media.licdn.com/dms/image/C4D0BAQEPccF4qQvJRg/company-logo_100_100/0/1631315480972?e=1711584000&v=beta&t=cTfLTngLX1cMMDIN-pLDDPnM3lUDx4p_VpkpKBmgZQg",
      name: "Vijitha Wijesuriya",
      company: "East West Properties PLC",
      number: "",
      email: "",
    },
    {
      avatar:
        "https://media.licdn.com/dms/image/C4D0BAQHcLU-1d3sY8A/company-logo_200_200/0/1631334337353?e=1711584000&v=beta&t=6uokxRgzT1AqY7jtyt3l5f21tdiW-dlOBugNaTDOOlM",
      name: "ACQUEST (Pvt) Ltd",
      company: "",
      number: "",
      email: "",
    },
    {
      avatar:
        "https://media.licdn.com/dms/image/C4D03AQGjtjaFmKjAmw/profile-displayphoto-shrink_200_200/0/1545890996096?e=1708560000&v=beta&t=uiZjm1sNAv0PGEiBCxO184J6S5wo661QiUpbZuW6fP4",
      name: "Chandaka de Soysa",
      company: "ACQUEST (Pvt) Ltd",
      number: "",
      email: "",
    },
  ];

  return (
    <List
      itemLayout="horizontal"
      dataSource={agentData}
      renderItem={(item, index) => (
        <List.Item
          actions={[
            // <Button key="list-loadmore-edit">email</Button>,
            <Button key="list-loadmore-more">enquire</Button>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={item.avatar} />}
            title={<a href="https://ant.design">{item.name}</a>}
            description={item.company}
          />
        </List.Item>
      )}
    />
  );
};
