import { Button } from "antd";

export const CondominiumStatsRental = ({ condominiumAnalytics }) => {
  const { unitMaxRentPrice, unitMinRentPrice } = condominiumAnalytics;
  const data = [
    {
      title: "Rental Range",
      value: `LKR${Intl.NumberFormat("en-US").format(
        unitMaxRentPrice
      )} - ${Intl.NumberFormat("en-US").format(unitMinRentPrice)}`,
    },
    // { title: "Investment Yield", value: "4%-6%" },
  ];

  const listData = data.map((data) => {
    return (
      <Button shape="round" style={{ margin: "3px" }}>
        <small>{data.title}: </small>
        {data.value}
      </Button>
    );
  });

  return <div>{listData}</div>;
};
