import { Avatar, Button, List } from "antd";
import { docoumentIcon } from "./iconData";

export const Documents = () => {
  const documentData = [
    {
      avatar: docoumentIcon,
      name: "Rental Agreement",
      company: "ACQUEST (Pvt) Ltd",
      number: "",
      email: "",
    },
    {
      avatar: docoumentIcon,
      name: "Sales & Purchase Agreement",
      company: "East West Properties PLC",
      number: "",
      email: "",
    },
    {
      avatar: docoumentIcon,
      name: "Broker Agreement",
      company: "",
      number: "",
      email: "",
    },
    {
      avatar: docoumentIcon,
      name: "Co-Broker Agreement",
      company: "",
      number: "",
      email: "",
    },
  ];

  return (
    <List
      itemLayout="horizontal"
      dataSource={documentData}
      footer={
        <div style={{ textAlign: "center" }}>
          <small>{"provided by "}</small>
          <a target="_blank" rel="noreferrer" href="https://www.desaram.com/">
            {"D.L &F DE SARAM"}
          </a>
        </div>
      }
      renderItem={(item, index) => (
        <List.Item
          actions={[
            // <Button key="list-loadmore-edit">email</Button>,
            <Button disabled key="list-loadmore-more">
              Download
            </Button>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={item.avatar} shape="square" />}
            title={item.name}
          />
        </List.Item>
      )}
    />
  );
};
