import { Button, Table, Input, Space } from "antd";
import { useRef, useState } from "react";
// import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

export const DevelopmentTableList = ({ developmentDb }) => {
  const [developmentData, setDevelopmentData] = useState([]);
  console.log(developmentData);

  // table search feature
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  console.log(searchText);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          {
            // padding: 8,
          }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex && text,
  });

  const columns = [
    {
      title: "Development Name",
      dataIndex: "developmentName",
      key: "developmentName",
      ...getColumnSearchProps("developmentName"),
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            setDevelopmentData(record);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a, b) => a.developmentName.length - b.developmentName.length,
      sortDirections: ["descend"],
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.cityRef - b.cityRef,
    },
    {
      title: "Completion",
      dataIndex: "completionYear",
      key: "completionYear",
      sorter: (a, b) => a.completionYear - b.completionYear,
      render: (year, record) =>
        year === 0 ? (
          <small style={{ fontSize: "12px" }}>Construction</small>
        ) : (
          year
        ),
    },
    {
      title: "Floors",
      dataIndex: "floors",
      key: "floors",
      sorter: (a, b) => a.floors - b.floors,
    },
    {
      title: "Units",
      dataIndex: "totalUnits",
      key: "totalUnits",
      sorter: (a, b) => a.totalUnits - b.totalUnits,
    },
    // {
    //   title: "Service Charge",
    //   dataIndex: "serviceCharge",
    //   key: "serviceCharge",
    // },
    {
      title: "Service Charge",
      dataIndex: "serviceCharge",
      key: "serviceCharge",
      sorter: (a, b) => a.serviceCharge - b.serviceCharge,
    },
    // {
    //   title: "Developer",
    //   dataIndex: "developer",
    //   key: "developer",
    // },
  ];

  return (
    <Table
      pagination={{ defaultPageSize: 100 }}
      size="small"
      columns={columns}
      dataSource={developmentDb}
    />
  );
};
