export const Location = ({ googleLocation }) => {
  return (
    <iframe
      src={googleLocation}
      width="100%"
      height="450"
      style={{ border: "0" }}
      allowfullscreen=""
      loading="lazy"
      title="google maps"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};
