import { Card, Col, Row, Statistic } from "antd";

export const SearchBar = ({ propertySearchStats, loading }) => {
  console.log(propertySearchStats);

  return (
    <Row
      gutter={[18, 18]}
      style={{ textAlign: "center", position: "relative" }}
    >
      <Col span={24} style={{ top: "-30px", maxWidth: "800px" }}>
        <Card>
          <div>
            <h3>
              {
                "An open source initative to provide a singular data resource for condomiums in Sri Lanka. "
              }
            </h3>
            <div style={{ textAlign: "left" }}>
              <p>
                {
                  "The current supply in the Colombo District is nearly 28,000 residential units which stood at 90.6% of the total supply at the end of the 1st half of 2022. Research and the Condominium Management Authority (CMA), the recognized supply of condominium residential units in Sri Lanka will reach approximately 39,000+ apartment parcels/units by 2025. 94.3% of these units are located in the Western Province, with 92.2% of these units located in the Colombo District"
                }
              </p>
            </div>
          </div>

          <div style={{ textAlign: "left" }}>
            <p>
              Currently listing {propertySearchStats.developmentCount}{" "}
              developments comprising of{" "}
              {Intl.NumberFormat("en-US").format(
                propertySearchStats.totalUnitCount
              )}{" "}
              Units accounting for approximately{" "}
              {Math.floor((propertySearchStats.totalUnitCount / 28000) * 100)}%
              of residential units in the Colombo District.
            </p>
          </div>
          <br></br>
          <Row gutter={[18, 18]}>
            <Col span={6}>
              <Statistic
                loading={loading}
                title="Condominiums"
                value={propertySearchStats.developmentCount}
              />
            </Col>
            <Col span={6}>
              <Statistic
                loading={loading}
                title="Units"
                value={Intl.NumberFormat("en-US").format(
                  propertySearchStats.totalUnitCount
                )}
              />
            </Col>
            <Col span={6}>
              <Statistic
                loading={loading}
                title="Under Development"
                value={"N/A"}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
