export const bedroom = (
  <img
    style={{ width: "25px" }}
    alt="bedroom outline"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZUlEQVR4nO2WPUsEMRCGH1FsROGwkUM7C8HK9qxEK7XXH+AfEITrLfyqxav9D2LhB9gIgoitYGNzNiIoZ6GIjEQmsMjF1QXX5JwHhmSys5m8mZBdMAzDMAzjg15gE7gFmsC6jiXHBiCfbI0Eaeria8Ck9t1YcohayE8GMSGRIVaRyBCrSGRIp1dkHtgBLoEnHXPtBbAFjJOIEMmxN6AB9JGAkEOtzAhQAYaAKWAbeNaYM33mOf3BZhS1FnAMzH1XiFtkV2ADxoBrjTsAutu8X4at5gm513bxi2qOAncatxyY7zeoAnXgRXPNtkvs/aWMILf7IRY07gEYKPnWq2uuo+zCPd53R2pP+zfARGAyF3eucSslC6lqrsfswj1Zvx84Uf8V2AVmgEGgBxgGpoF9jbn6g++Q+HxFr9/YjI4VkhpiQiJDrCKRIanfWv9PSKyICUmtIhI4g7Eheb8oyQsxDMMwDAryDq3Vwnyhbkg0AAAAAElFTkSuQmCC"
  ></img>
);

export const bathroom = (
  <img
    alt="bathroom outline"
    style={{ width: "25px" }}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOElEQVR4nO2aQUtVQRTHf7SRUiNSUNHWoR+iQlfaJhduSwgsQqVlfYryO0QWCtFKLHXh66XY5umiTYkgCGLoImtZNwb+Nya7977r0+s7I+8HB96dOTNz/m9m7rx3zwX7XAQeA2vAD9kqMAk0EQjdwAYQpVgF6ME4TZ6Iz8Aw0A60ALe9uor1mZn0RFxJqG8FNuUzgWFWFORIhs8d+bg9Y45BYAn4pSDngIEU31b5fMcYT4HfCZvalT1JaRP7mOGmJ+IZ0CGb8oK9EYIQt5xcQG8S6t6qbiEEIe6gi6rYYQhCSjmELIcgpFZMCilrZrI+ByGk5C2ftM9BCKkFc0KOLp9q12aFHF0+1a7NCqkVU0KSlk05Z5kpIUnLppSzzJSQkxDFQso5fhqEYETnTUioROdWSBS48dFAECe1cjw9XcCsCl9hn9eKdUax/0OXKvexz4Fi/U9ETCgbP6oWZ0PIGRM1ZiS0GfkpB5eDsMrljId1f9mS03Xs0qsYXW4klRk5jWGXh3kO7vsZD4utPewezXK6BHyTY1pypZ7cUmx7yvZmMi7nL8BV7NDm5Qwf5WlwAXinBiV1UG/agA+KaV4x5r7FfVLDr0A/9WPAm4k15Q2PRTMw7R0+i7qb9RZ8zrQAfcADb2M7e6k9XDMuOb9exz9NFWDo9L4nuKeOXYpsp8DAd7w03F0KeCdkQZ0/p3imNNZ7jX3qub1toJPi6dRY8bhp2apj4TrZBV6c8Zs41zTmbka2qgFW+QMx6eTjys5T6AAAAABJRU5ErkJggg=="
  ></img>
);

export const floorPlan = (
  <img
    alt="floor plan outline"
    style={{ width: "35px" }}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHElEQVR4nO2auU7EMBCGPyp4AMTRIAQdgpKj5argpSgouCUkHoJLouEFKNjNVjwFNEhcokBCGEWaRS52WdvE2ZjML7lx4pnxn5nfkyigUCgUCkWOIeAIeACM42jSGy0Pe2X5uQcOZc8/OAoMrhf+uvmYfg5sA+0nP+/gLMRhKGL4WbQy4Qe+gaZMgG2364RrTaVUAk0fAozDaDgElhWw+SL9eBMQG/3yowRoBqAlYLxqpMPp0IxQm5XXgMxTnX0DiwUVQfQUQI9Bfik9oyKIiqCpgDqX5Uc7Qe0ESUMEW5H8JNMJFqURyWqAUQL4XxkwA2wDt8CjRwYUNfomghPAWZegGikRkAWI4DLwJGvegGNgBRh3WJu8BqwDH2LrHBj1XJ80AZNWne8AAwE2kibg1HryIZtvl1yMfiO6CM4CX8ALMEb/UboIbsk9J1QDpZdAQ9bnJ0AtCXiQ9cPUlIBPj+bkhvgoXQSzwA4tFvraCf6G5AgoGkoAmgEkVQItRxEMDayTvy95p8g1ZxeYq5MImi7jCpiqgwgaq6Faldb6VeaegY26EGAj/5ByIfP5t4ZNakYA8nq9Z2XCNDUjoE3CpVy/JhIBpiKjG0YsTfCx05OALBECEGF0sdHwIaAqcIlrTe65+4vde5lYID0CxuWed0ebS51+lz+oQJqHlsBAoM0928iQkNDOhCqNov9Kz/e4Dww6ZoxCoVAo+I/4Bt6j7tNbHzv1AAAAAElFTkSuQmCC"
  ></img>
);
export const docoumentIcon = (
  <img
    style={{ width: "30px" }}
    alt="document outline"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+klEQVR4nO2WwQ7CMAxD/RObxBchfp8h8SlwMxd2ZDSrm2ZdLOW4dk92rAKp8UXBPADMI4AQwBPAJQJI7ffsDaMCWXrHTAUyAbj3dOYtAsHXidWZxduZK4CX0NGppzM3cTRnizNR+p8/olkME6X/ubFjRTFT9n+NMzQmIGz/0zjNQLxahq1B4ARDDxAPGHqBtIahJ8gWzPoC2LvU9AZRwbiD7O5/o0KA1NzTHKRUCRLdkb3R42lBWDnFP2DUeUHUSpDojsgjytFBKH56HKa1/imXPfqOyM9hgth0mGWnsTbV52N4ELWa38MEsSkdKVVGK2q06DQJksIB9AHlflWdlAnHUwAAAABJRU5ErkJggg=="
  />
);
