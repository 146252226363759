import { Col, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { DevelopmentTableList } from "../developments/developmentTableList";

export const ListDevelopments = () => {
  const [developmentDb, setDevelopmentDb] = useState([]);
  // const [developmentStats, setDevelopmentStats] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDevelopments = () => {
    axios
      .get(".netlify/functions/getDevelopments", {
        baseURL: "/",
      })
      .then((response) => {
        setDevelopmentDb(response.data.developmentDb);
        // setDevelopmentStats(response.data.stats);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDevelopments();
  }, []);

  return (
    <Skeleton loading={loading}>
      <Col span={24}>
        <DevelopmentTableList developmentDb={developmentDb.data} />
      </Col>
    </Skeleton>
  );
};
