import { Menu } from "antd";
import { useState } from "react";

export const NavBarProfile = ({ developmentStats }) => {
  const { unitSaleCount, unitRentCount } = developmentStats;
  const [current, setCurrent] = useState("mail");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const items = [
    {
      label: <a href="#sales">SALE ({unitSaleCount})</a>,
      key: "sale",
      link: "#sales",
    },
    {
      label: <a href="#rentals">RENTAL ({unitRentCount})</a>,
      key: "rental",
    },
    {
      label: <a href="#agents">AGENTS</a>,
      key: "agents",
    },
    {
      label: <a href="#location">LOCATION</a>,
      key: "location",
    },
  ];
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  );
};
