import { Col } from "antd";

export const Hero = () => {
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <img
          style={{ maxWidth: "900px" }}
          src={
            "https://app-property-search.s3.ap-southeast-1.amazonaws.com/landscape-colombo.jpg"
          }
          alt="rocket ready to launch"
        />
      </div>
    </Col>
  );
};
