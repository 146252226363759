import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, message, Radio, Row } from "antd";

import axios from "axios";

const CollectionCreateForm = ({
  open,
  onCreate,
  onCancel,
  saleData,
  developmentId,
  developmentName,
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  useEffect(() => {
    form.setFieldsValue({
      message: `Hi, interest in your ${saleData.bedrooms} bedroom at Crescat. Please send me more information. `,
    });
  }, [form, saleData]);

  return (
    <Modal
      open={open}
      style={{
        top: 200,
      }}
      title="Send Enquiry"
      okText="Send"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();

            const inquiryData = {
              developmentName,
              ...values,
              TransactionType: "sale",
              bathrooms: saleData.bathrooms,
              bedrooms: saleData.bedrooms,
              developmentId: developmentId,
              unitId: saleData.id,
            };

            onCreate(inquiryData);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Row gutter={[18, 18]}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              style={{ margin: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Please add Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              style={{ margin: "0px" }}
              label="Contact Number"
              name="contactNumber"
              rules={[
                {
                  required: true,
                  message: "Please add Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={"whatsApp?"}
              style={{ margin: "0px" }}
              name={"whatsApp"}
              rules={[{ required: true, message: "whatsApp?" }]}
            >
              <Radio.Group checked={false} buttonStyle="solid">
                <Radio.Button value={true}>{"Yes"}</Radio.Button>
                <Radio.Button value={false}>{"No"}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email "
              name="email"
              style={{ margin: "0px" }}
              rules={[
                {
                  required: false,
                  message: "Please add Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Message" name="message">
              <TextArea
                rows={4}
                rules={[
                  {
                    required: false,
                    message: "add a message",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export const EnquireModal = ({ developmentId, saleData, developmentName }) => {
  const [open, setOpen] = useState(false);

  const postDiscordMessageEnquirySale = async (values) => {
    console.log(values);

    return axios
      .post(".netlify/functions/postDiscordMessageEnquirySale", {
        values,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function error(error) {
        const errorMessage = error.response.data;
        console.log(errorMessage);
      });
  };

  const postSalesEnquiry = async (enquiryData) => {
    return axios
      .post(".netlify/functions/postSalesEnquiry", {
        enquiryData,
      })
      .then(function (response) {
        setOpen(false);
        message.success({
          content: `Enquiry Sent!`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
      });
  };

  const onCreate = (values) => {
    postDiscordMessageEnquirySale(values);
    postSalesEnquiry(values);
  };

  return (
    <div>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        <small>{"Enquire"}</small>
      </Button>
      <CollectionCreateForm
        open={open}
        developmentId={developmentId}
        saleData={saleData}
        developmentName={developmentName}
        onCreate={onCreate}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
