import { Button } from "antd";

export const CondominiumStats = ({ condominiumAnalytics, serviceCharge }) => {
  const {
    unitMinSalePrice,
    unitMaxSalePrice,
    unitMinPricePerSqft,
    unitMaxPricePerSqft,
  } = condominiumAnalytics;

  const data = [
    {
      title: "per Sqft Range",
      value: `LKR${Intl.NumberFormat("en-US").format(
        unitMaxPricePerSqft
      )} - LKR${Intl.NumberFormat("en-US").format(unitMinPricePerSqft)}`,
    },
    {
      title: "Price Range",
      value: `LKR${Intl.NumberFormat("en-US").format(
        unitMaxSalePrice
      )} - LKR${Intl.NumberFormat("en-US").format(unitMinSalePrice)}`,
    },
    { title: "Monthly Service Charge", value: `${serviceCharge} per sqft` },
  ];

  const listData = data.map((data) => {
    return (
      <Button shape="round" style={{ margin: "3px" }}>
        <small>{data.title}: </small>
        {data.value}
      </Button>
    );
  });

  return <div>{listData}</div>;
};
