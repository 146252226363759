import { Col, Row, Divider } from "antd";
import { PageLayout } from "../layout/pageLayout";
import React, { useEffect, useState } from "react";

import { Gallery } from "./gallery";

import { Location } from "./location";
import { ListCondominiumSaleData } from "./listCondominiumSaleData";
import { Agents } from "./agents";
import { ListCondominiumRentalData } from "./listCondominiumRentalData";
import { NavBarProfile } from "./navBarProfile";
import { Documents } from "./documents";
import { CondominiumDetails } from "./condominiumDetails";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CondominiumStats } from "./condominiumStats";
import { CondominiumStatsRental } from "./condominiumStatsRental";

// import { CondominiumProfileFeatureProperties } from "./condominiumProfileFeatureProperties";
// import { CondominiumNewDevelopmentsAds } from "./condominiumNewDevelopmentsAds";

export const CondominiumProfile = () => {
  const { developmentId } = useParams();

  const [developmentData, setDevelopmentData] = useState({ images: [] });
  const [developmentStats, setDevelopmentStats] = useState("");
  const [condominiumAnalytics, setCondominiumAnalytics] = useState("");

  useEffect(() => {
    axios
      .get(".netlify/functions/getDevelopmentById", {
        baseURL: "/",
        params: { developmentId },
      })
      .then(function (response) {
        setDevelopmentData(response.data.developmentDoc.data);
        setDevelopmentStats(response.data.stats);
        setCondominiumAnalytics(response.data.condominiumAnalytics);
      });
  }, [developmentId, setDevelopmentData]);

  const { developmentName, images, description, googleLocation } =
    developmentData;
  console.log(developmentData);

  return (
    <PageLayout>
      <Row
        gutter={[18, 18]}
        style={{ maxWidth: "1000px", justifyContent: "center" }}
      >
        <Col span={24}>
          <div style={{ textAlign: "left" }}>
            <h1>{developmentName}</h1>
          </div>
        </Col>
        <Col span={24}>
          <Gallery images={images} />
        </Col>
        <Col span={24}>
          <NavBarProfile developmentStats={developmentStats} />
        </Col>
        <Col span={24}>
          <Divider orientation="left">Details</Divider>
          <CondominiumDetails
            description={description}
            descriptionStatsData={developmentData}
          />
        </Col>

        {/* <Divider orientation="left">Fetaured Units</Divider>
        <Col xs={24} sm={24} md={24}>
          <CondominiumProfileFeatureProperties />
        </Col> */}

        <Divider orientation="left">UNITS FOR SALE</Divider>
        <Col id="sales" span={24} style={{ textAlign: "center" }}>
          <CondominiumStats
            condominiumAnalytics={condominiumAnalytics}
            serviceCharge={developmentData.serviceCharge}
          />
        </Col>
        <Col xs={24} sm={24} md={24}>
          <ListCondominiumSaleData
            developmentId={developmentId}
            developmentName={developmentName}
          />
        </Col>

        {/* <Divider style={{ margin: "0px" }} orientation="right">
          {"New Developments"}
        </Divider>
        <Col span={24}>
          <CondominiumNewDevelopmentsAds />
        </Col> */}
        <Divider orientation="left">RENTAL UNITS</Divider>
        <CondominiumStatsRental condominiumAnalytics={condominiumAnalytics} />
        <Col id="rentals" xs={24} sm={24} md={24}>
          <ListCondominiumRentalData
            developmentId={developmentId}
            developmentName={developmentName}
          />
        </Col>

        <Col xs={24} sm={24} md={12} id="agents">
          <Divider orientation="left">Agents Specialising in Crescat</Divider>
          <Agents />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Divider orientation="left">Documents</Divider>
          <Documents />
        </Col>

        <Divider id="location" orientation="left">
          Location
        </Divider>
        <Col span={24}>
          <Location googleLocation={googleLocation} />
        </Col>
      </Row>
    </PageLayout>
  );
};
