import { Row } from "antd";
import { Hero } from "../components/landingPage/hero";

import { DynamicMetaTags } from "../utils/dynamicMetaTags";

import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/layout/pageLayout";

import { SearchBar } from "../components/landingPage/searchBar";

// import { LastestProjects } from "../components/landingPage/lastestProjects";
// import { CurratedCollection } from "../components/landingPage/curatedCollections";
// import { LastestListings } from "../components/landingPage/latestListings";

import axios from "axios";

import { ListDevelopments } from "../components/landingPage/listDevelopments";

export const LandingPage = () => {
  const [propertySearchStats, setPropertySearchStatsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStats = () => {
    axios
      .get(".netlify/functions/getStats", {
        baseURL: "/",
      })
      .then((response) => {
        setPropertySearchStatsData(response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  // const featureDevelopments = [
  //   {
  //     title: "Crescat",
  //     url: "385554451863175234",
  //   },
  //   {
  //     title: "Monarch",
  //     url: "385554569997844546",
  //   },
  //   {
  //     title: "Emperor",
  //     url: "385554642517360704",
  //   },
  //   {
  //     title: "One Galle Face",
  //     url: "385555163239153728",
  //   },
  //   {
  //     title: "Iceland",
  //     url: "386595259538210893",
  //   },
  //   {
  //     title: "Altair",
  //     url: "386734453213364297",
  //   },
  //   {
  //     title: "7th Senses",
  //     url: "",
  //   },
  //   {
  //     title: "Havelock City",
  //     url: "",
  //   },
  //   {
  //     title: "Colombo City Center",
  //     url: "",
  //   },
  //   {
  //     title: "Fairway",
  //     url: "",
  //   },
  //   {
  //     title: "On320",
  //     url: "",
  //   },
  // ];

  // const listFeatured = featureDevelopments.map((data) => {
  //   return (
  //     <Col>
  //       <Link to={data.url}>
  //         <Card hoverable size="small" style={{ textAlign: "center" }}>
  //           <Row>
  //             <Col span={24}>{data.title}</Col>
  //           </Row>
  //         </Card>
  //       </Link>
  //     </Col>
  //   );
  // });

  return (
    <PageLayout>
      <DynamicMetaTags ogTitle={`app-boiler-plate-v4-auth0`} ogImage={""} />
      <Row style={{ maxWidth: "1024px", justifyContent: "center" }}>
        <Hero />
        <div>
          <SearchBar
            propertySearchStats={propertySearchStats}
            loading={loading}
          />
        </div>
        <br></br>
        <Row gutter={[5, 18]}>
          <ListDevelopments />
          {/* <Card style={{ display: "flex" }}>
            <Divider>{"Featured Developments"}</Divider>
            <Row gutter={[5, 6]}>{listFeatured}</Row>
          </Card> */}
          {/* <Col span={24}>
            <LastestProjects />
          </Col>
          <Col span={24}>
            <CurratedCollection />
          </Col>
          <Col span={24}>
            <LastestListings />
          </Col> */}
        </Row>
      </Row>
    </PageLayout>
  );
};
